<template>
  <span>
    <v-app-bar height="58" app color="#1d2e43" dark flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img
          width="100"
          contain
          src="https://static.movia.media/assets/logo-movia-w.png"
        ></v-img>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-autocomplete
        :items="items"
        item-text="truck_id"
        class="mx-4"
        hide-details
        flat
        label="Search fleet..."
        solo-inverted
        dense
      >
        <template v-slot:item="obj">
          <router-link
            :to="`/analytics/id=${obj.item.truck_id}`"
            class="truck-list-item"
          >
            <v-row align="center" class="py-2">
              <v-col cols="2">
                <v-img
                  height="50"
                  :src="obj.item.cover_photo"
                  v-if="obj.item.cover_photo"
                >
                </v-img>
                <v-img
                  height="50"
                  src="../assets/truck.png"
                  v-if="!obj.item.cover_photo"
                >
                </v-img>
              </v-col>
              <v-col cols="3"> Truck #{{ obj.item.truck_id }} </v-col>
              <v-col cols="7">
                {{ obj.item.city }}, {{ obj.item.region }}
              </v-col>
            </v-row>
          </router-link>
        </template>
      </v-autocomplete>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-sheet color="#1d2e43" dark class="pa-4" style="text-align: center">
        <v-avatar class="mb-4" size="64">
          <v-btn link to="/profile">
            <v-icon size="64" dark> mdi-account-circle </v-icon>
          </v-btn>
        </v-avatar>
        <div>
          {{ profile.first_name }}
          {{ profile.last_name }}
          <br />
          <small>{{ profile.email }}</small>
        </div>
      </v-sheet>

      <!-- <v-list-item style="text-align: center">
                <v-list-item-content v-if="isViewingSingleTruck">
                <v-list-item-title>
                {{ $store.state.currentTruck.display_name }}
                </v-list-item-title>
                <v-img
                cover
                max-height="140"
                v-if="$store.state.currentTruck.cover_photo"
                :src="$store.state.currentTruck.cover_photo"
                ></v-img>
                <v-img
                cover
                max-height="140"
                v-if="!$store.state.currentTruck.cover_photo"
                src="../assets/truck.png"
                ></v-img>
                </v-list-item-content>
                <v-list-item-content v-else>
                <v-list-item-title class="text-h6">
                Movia Analytics
                </v-list-item-title>
                </v-list-item-content>
                </v-list-item> -->

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item link to="/overview">
          <v-list-item-icon>
            <v-icon>mdi-truck</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Fleet Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/explore"
          v-if="profile.is_superuser"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Explore</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
          link
          to="/activity"
          v-if="profile.is_staff || profile.is_superuser"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-search-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Truck Activity</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-list-item
          link
          to="/insights"
          v-if="profile.is_staff || profile.is_superuser"
        >
          <v-list-item-icon>
            <v-icon>mdi-eye-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Insights</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!--
                        <v-list-item link to="/timeline">
                        <v-list-item-icon>
                        <v-icon>mdi-clock-fast</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>Timeline</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item>

                        <v-list-item link to="/inventory">
                        <v-list-item-icon>
                        <v-icon>mdi-format-list-checks</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title>Inventory</v-list-item-title>
                        </v-list-item-content>
                        </v-list-item> -->

        <v-list-item link @click="logOut()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list v-if="isOnInsightPage" nav class="pl-4 mt-8">
        <v-text-field
          label="Filter"
          rounded
          append-icon="mdi-magnify"
          solo
          v-model="filterText"
        ></v-text-field>
        <v-list-item href="#needs-attention" link>
          <v-list-item-content>
            <v-list-item-title>Action Items</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item href="#campaigns" link>
          <v-list-item-content>
            <v-list-item-title>Campaigns</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          link
          v-for="campaign in filteredCampaigns"
          :key="campaign.id"
          :href="`#${campaign.id}`"
        >
          <v-list-item-content>
            <v-list-item-title>{{ campaign.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item disabled link>
          <v-list-item-content>
            <v-list-item-title>No Campaign</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>

<script>
import auth from "@/services/auth.service";
export default {
  name: "TheHeader",
  data() {
    return {
      profile: this.$root.profile,
      drawer: null,
      items: this.$root.truckList,
      // isViewingSingleTruck: false,
      isOnInsightPage: false,
      filterText: "",
    };
  },
  watch: {
    $route: {
      handler(to) {
        // this.isViewingSingleTruck = to.name === "Analytics";
        this.isOnInsightPage = to.name === "Insights";
      },
      immediate: true,
    },
  },
  computed: {
    filteredCampaigns() {
      if (this.filterText) {
        return this.$store.state.campaigns.filter((campaign) =>
          campaign.name.toLowerCase().includes(this.filterText.toLowerCase())
        );
      } else return this.$store.state.campaigns;
    },
  },
  methods: {
    logOut: auth.logout,
  },
};
</script>

<style scoped>
#link-enroll {
  white-space: normal;
  font-size: 0.75rem;
  text-align: center;
}

#link-logo {
  display: flex;
  height: 100%;
}
.truck-list-item {
  text-decoration: none;
}
</style>
